<template>
    <div class="page-content">
        <div class="banner-wrap"></div>
        <div class="main-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item :to="{ path: '/case/index' }"
                    >调解案件查询</el-breadcrumb-item
                >
                <el-breadcrumb-item>案件查询</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="table-wrap white-bg">
                <div class="table-content">
                    <el-table
                        :data="tableData"
                        style="width: 100%"
                        :header-cell-style="{
                            background: '#d4d8db',
                            color: '#606266',
                            'text-align': 'center',
                            'font-size': '12px',
                        }"
                        :cell-style="{
                            'text-align': 'center',
                            'font-size': '12px',
                        }"
                    >
                        <el-table-column fixed prop="caseId" label="调解案号" />
                        <!-- <el-table-column prop="name" label="申请人"/> -->
                           <el-table-column prop="orgName" label="申请人"/>
                        <!-- <el-table-column prop="caseStatusName" label="调解进展" /> -->
                         <el-table-column label="调解进展">
                            <template #default> 调解中 </template>
                        </el-table-column>
                        <!-- <el-table-column prop="orgName" label="被申请人" /> -->
                           <el-table-column prop="name" label="被申请人" />
                        <el-table-column prop="createTime" label="提交日期" />

                        <el-table-column fixed="right" label="操作">
                            <template #default="scope">
                                <p
                                    class="look hand"
                                    @click="handleClick(scope.row)"
                                >
                                    查看
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!-- <div class="listpage center">
                    <el-config-provider :locale="locale">
                        <el-pagination
                            v-model:current-page="page"
                            v-model:page-size="pageSize"
                            :page-sizes="[10, 20, 30, 50]"
                            :small="small"
                            :disabled="disabled"
                            :background="background"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            @size-change="onPageSizeChange"
                            @current-change="onPageChange"
                        />
                    </el-config-provider>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import locale from "element-plus/lib/locale/lang/zh-cn";
export default {
    data() {
        return {
            locale: locale, //分页中文  20230907115846956
            searchFlag: this.$route.query.searchFlag,
            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,
            disabled: false,
        };
    },
     created() {
        this.getData();
    },
    methods: {
        async getData() {
            let params = {
                // pageSize: this.pageSize,
                // pageNum: this.page,
                searchFlag: this.searchFlag,
            };
            const rsp = await this.$http("getCaseList", params);
            if (rsp.code != 200)
                return this.$message({ message: rsp.message, type: "warning" });
            // this.total = rsp.data.total;
            this.tableData = rsp.data;
        },
        // 页码改变函数
        onPageChange(currentPage) {
            this.page = currentPage;
            this.getData();
        },

        onPageSizeChange() {},
        handleClick(item) {
            this.$router.push({ path: "/case/details", query: { id: item.id,searchFlag: this.searchFlag} });
        },
    },
};
</script>

<style lang="less" scoped>
.page-content {
    .main-content {
        width: 1200px;
        margin: auto;
    }
    .el-breadcrumb {
       padding: 40px 0 10px;
    }
    .banner-wrap {
        background-image: url("../../assets/images/med-banner.png");
        width: 100%;
        // height: 705px;
        background-position: center;
        background-repeat: no-repeat;
        // background-size: cover;

        background-position: 50%;
        height: 290px;
        // position: relative; med-banne.png
    }
    .table-wrap {
        padding: 29px 38px 60px;
        margin: 47px auto 80px;
        box-shadow: 0 2px 10px 0 hsla(0, 0%, 77.6%, 0.71);
        .table-content {
            min-height: 509px;
            .look {
                color: #e52222;
            }
        }
    }
}
</style>